@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-container {
  max-width: 642px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

.header-iputs {
  max-width: 232px;
  width: 100%;
}

.w-20 {
  width: 40%;
}

.visibility-hidden {
  visibility: hidden;
}

.icon-with-input {
  top: 5px;
  left: 5px;
}

.input-with-icon {
  padding-left: 2rem;
}

.main-body {
  margin-top: 80px;
  overflow-y: auto;
  height: calc(100% - 195px);
}

.question-header {
  background: rgba(255, 255, 255);
  padding-top: 10px;
  padding-bottom: 10px;
}

.question-text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.anwser-wrapper {
  background: rgba(243, 244, 246, 0.05);
  padding-top: 16px;
  margin-bottom: 1rem;
}

.question-input-area {
  border-radius: var(--spacing-sm, 8px);
  border: 1px solid #eee;
  box-shadow: 0 4px 6px -1px rgba(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  background: var(--colors-dark, #ffffff);
  padding-right: 8px;
  width: 100%;
  position: relative;
  height: 38px;
}

.send-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.type-icon {
  position: absolute;
  top: 8px;
  left: 8px;
}

.custom-footer {
  height: 75px;
}

.header-height {
  height: 80px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.scrollbox {
  overflow-y: scroll;
}

.scrollbox:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (hover: none) {
  .scrollbox::-webkit-scrollbar {
    width: 8px;
  }

  .scrollbox::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.choose-chatbot,
.user-id {
  margin-right: 12px;
}

textarea {
  resize: none;
}

.send-icon-img {
  width: 28px;
  height: 28px;
}

@media only screen and (max-width: 600px) {
  .header-items {
    flex-wrap: wrap;
  }

  .header-items {
    flex-wrap: wrap;
  }

  /* .button-label {
    display: none;
  } */
  .header-iputs.choose-chatbot {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 8px;
  }

  .header-iputs.user-id {
    max-width: calc(60% - 12px);
    width: 100%;
  }

  .header-height {
    height: 158.5px;
  }

  .main-body {
    margin-top: 158.5px;
    overflow-y: auto;
    height: calc(100% - 240px);
  }

  .question-input-area {
    min-height: 60px;
  }

  .send-icon-img {
    width: 48px;
    height: 48px;
  }

  .custom-footer {
    height: 98px;
  }
}
