.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.auth h2 {
  font-size: 40px;
  color: #000;
  margin: 0 0 20px;
}

.auth .para {
  padding-top: 20px;
}

.auth .para a {
  color: #2f7d31;
}

.auth .signup {
  padding: 0;
  margin: 0;
}
.auth h4 {
  padding: 0 0 20px;
}

.auth .MuiFormControl-root {
  margin-bottom: 20px;
}

.custom .MuiInputBase-root {
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .first-step,
  .auth > .MuiBox-root {
    width: 80% !important;
  }
}
