.chat-container {
  background: #343541;
  height: 100vh;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
}

.chat-threads {
  background: #202123;
  padding: 8px;
  width: 260px;
  transition: 0.3s;
}

.chat-threads button.hide {
  display: none;
}

.chat-threads button.show {
  display: flex;
}

button.off {
  margin: 8px;
}

.chat-threads.off {
  width: 0;
  padding: 0;
  transition: 0.3s;
}

.header {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.header button {
  border: 1px solid rgba(255, 255, 255, 20%);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  flex: 1;
  justify-content: flex-start;
}

.header button:first-child svg {
  margin-right: 10px;
}

.header button:last-child {
  flex: initial;
}

.thread-list ul li {
  border-radius: 8px;
  display: flex;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  align-items: center;
  cursor: pointer;
}

.thread-list ul li:not(:last-child) {
  margin-bottom: 8px;
}

.thread-list ul li .buttons {
  margin-left: auto;
}

.thread-list ul li .buttons button {
  padding: 4px;
  opacity: 0.7;
}

.thread-list ul li .buttons button:hover {
  opacity: 1;
}

.thread-list ul li.selected {
  background: #343541;
}

.thread-list ul li .buttons button svg {
  font-size: 18px;
}

.thread-list ul li:hover {
  background: rgba(42, 43, 50, 1);
}

.thread-list ul li svg.chat-bubble {
  margin-right: 10px;
}

.user-question {
  background: #343541;
}

.chat-messages h4,
.chat-messages h5 {
  padding: 30px 50px;
  color: #fff;
  display: flex;
  width: 70%;
  margin: 0 auto;
}

.chat-messages h5 {
  background: #444654;
}
.chat-messages h5 svg {
  background-color: rgb(25, 195, 125);
  color: #fff;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-right: 8px;
}
.chat-screen {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  width: 100%;
  scrollbar-color: darkgrey darkgrey;
  scrollbar-width: thin;
  background: #444654;
}
.chat-messages {
  width: 100%;
}
.search-input {
  background: linear-gradient(180deg, rgba(53, 55, 64, 0), #343541 58.85%);
  padding: 30px 0;
  margin-top: auto;
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input form {
  width: 70%;
  position: relative;
}

.search-input textarea {
  width: 100%;
  background: #40414f;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 70px;
}
.search-input textarea:focus-visible {
  outline: none;
}
/* .search-input input:focus-visible {
  outline: none;
} */
.search-input button {
  right: 0;
  position: absolute;
  bottom: 0;
  top: 0;
}
.chatbot-dropdown label {
  color: #fff;
}
.select-bot {
  background: #40414f;
  padding: 10px 15px;
  border-radius: 8px;
  color: #fff;
  margin: 60px auto;
  font-weight: 600;
  font-size: 14px;
  width: 250px;
  text-align: left;
}

.MuiSelect-popper {
  background: #40414f;
  width: 250px;
  border-radius: 8px;
  overflow: hidden;
  top: 10px !important;
}

.chatbot-dropdown {
  display: flex;
}

.click-thread {
  display: flex;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  padding: 10px;
}

.click-thread p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
}
.title-container {
  padding-right: 10px;
}
.title-container .buttons {
  display: flex;
  gap: 5px;
}
.click-thread input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
  padding: 2px 10px;
  color: #fff;
  border-radius: 8px;
}

.click-thread fieldset {
  border: none;
}

.thread-list {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 114px);
  scrollbar-color: darkgrey darkgrey;
  scrollbar-width: thin;
}

.footer-navigation h4 {
  text-transform: capitalize;
}
.footer-navigation {
  margin-top: auto;
}

.footer-navigation button > div {
  display: flex;
  align-items: center;
}
.footer-navigation button:hover {
  background: rgba(42, 43, 50, 1);
  border-radius: 8px;
}
.footer-navigation > button {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  justify-content: space-between;
}
.initials-avatar {
  background-color: #ef6c00;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
button.showMobile {
  display: none;
}
.chat-threads.off .footer-navigation {
  display: none;
}
.auth {
  width: 440px;
  margin: 0 auto;
}
.auth .MuiFormControl-root {
  width: 100%;
}

.chat-screen::-webkit-scrollbar {
  width: 10px;
}

.chat-screen::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.chat-screen::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.chat-screen::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.thread-list::-webkit-scrollbar {
  width: 5px;
}

.thread-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.thread-list::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.thread-list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
  visibility: hidden;
}

.thread-list:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.formatted-text {
  display: flex;
  flex-direction: column;
  background: none !important;
}

.formatted-text ul {
  list-style-type: disc;
  padding-left: 15px;
}

.formatted-text ol {
  list-style-type: number;
  padding-left: 15px;
}

.theme-icon button {
  color: #fff;
}

@media (prefers-color-scheme: light) {
  .chat-threads {
    background: #f9f9f9;
  }
  .chat-threads * {
    color: #000 !important;
  }
  .thread-list ul li:hover,
  .thread-list ul li.selected {
    background: #efefef;
  }
  .header button {
    border-color: #d9d9d9;
  }
  .chat-screen {
    background: #fff;
  }
  .search-input {
    background: linear-gradient(180deg, rgb(255 255 255 / 0%), #f7f7f7 58.85%);
  }
  .search-input textarea {
    background: #fff;
    color: #000;
  }
  .select-bot {
    background: #efefef;
    color: #000;
  }
  .MuiSelect-popper {
    background: #efefef;
  }
  .user-question,
  .chat-messages h5 {
    background: #fff;
  }
  .chat-messages h4,
  .chat-messages h5 {
    color: #000;
  }
  .footer-navigation button:hover {
    background: #fff;
  }
}

@media (max-width: 1024px) {
  .chat-threads {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 9;
  }
  button.showMobile {
    display: flex;
    position: absolute;
    right: 0;
    border: 1px solid rgba(255, 255, 255, 20%);
    color: #fff;
    border-radius: 8px;
    margin: 8px;
  }

  .chat-messages {
    width: 100%;
  }

  .search-input {
    width: 100%;
  }

  .chat-screen {
    padding: 60px 0 0;
  }
  .chat-messages h5,
  .chat-messages h4 {
    padding: 30px;
    width: 100%;
  }

  .auth {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
  }
  .mobile-header {
    width: 100%;
    position: absolute;
    background: #202123;
    z-index: 1;
    height: 60px;
  }
  .search-input form {
    width: 90%;
  }
  @media (prefers-color-scheme: light) {
    .mobile-header {
      background: #efefef;
    }
    button.showMobile {
      color: #000;
      border-color: #d9d9d9;
    }
  }
}
